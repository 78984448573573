import axios from "axios";
import redux from "redux/store";
import { setServerValidation, setSubmitIsLoading } from "redux/actions/main";
import { captureException } from "@sentry/nextjs";

const PUBLIC_API_KEY = process.env.NEXT_PUBLIC_KYNA_API_KEY;
const NEXTAUTH_URL = process.env.NEXTAUTH_URL
import store from 'store2';
import { isEmpty } from "lodash";

// import bcrypt from 'bcryptjs'
const UserSession = store.get('UserSession');

export async function submitLogin(formData) {
  const data = {
    username: formData.username,
    password: formData.password,
  };

  let response = {
    status: 204, //No Content
    data: null,
  };

  try {
    response = await KTPost(process.env.NEXTAUTH_URL + "api/users/login", data);
    if (response.status == 200 && response.data) {
      const user = response.data;
      return {
        id: user.id,
        name: user.person.first_name + " " + user.person.last_name,
        first_name: user.person.first_name,
        last_name: user.person.last_name,
        contact_no: user.person.contact_no,
        email: user.username,
      };
    } else {
      throw new Error("Something went wrong!");
    }
  } catch (error) {
    captureException(error);
    throw new Error(error?.message);  
  }
}

export const KTGet = async (url, params) => {
  let response = {
    status: 204, //No Content
    data: null,
  };

  try {
    response.status = 200;
    response.data = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": PUBLIC_API_KEY,
      },
      params: params,
    });
  } catch (error) {
    // console.log(Object.keys(error), error.message);
    response.status = 400; //Bad Request
    console.log("Error URL:", url);
    console.log("Error Message:", error);
    captureException(error);
  }

  // console.log("get response", response);

  if (response.status == 200 && response.data) {
    return response.data;
  }

  return response;
};

export const KTPost = async (url, formData) => {
  let response = {
    status: 204, //No Content
    data: null,
  };
  console.log('URL OF LOGS:', url)
  console.log('formData:', formData)
  // return
  let Activity_Logs = {
    status: 204, //No Content
    data: null,
  };
  // return
  try {
    response = await axios({
      method: "post",
      url: url,
      maxBodyLength: 15 * 1024 * 1024,
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": PUBLIC_API_KEY,
      },
      data: formData,
    });
    Activity_Logs = await ActivityLogs(url, formData)

  } catch (error) {
    captureException(error);
    response.error = error;
    redux.dispatch(setSubmitIsLoading(false));
    const { status } = response?.error?.toJSON();
    switch (status) {
      case 500: {
        if(error?.response?.data?.message){
          throw new Error(error?.response?.data?.message);
        }
        throw new Error("Server: Internal Server Error");
      }
      case 422:
        redux.dispatch(
          setServerValidation(error?.response?.data?.schemaErrors)
        );
        throw new Error("Server: Validation Error");
      case 404:
        throw new Error("Server: Not Found");
      case 401:
      case 403:
        break;
      case 400:
        if(error?.response?.data?.message){
          throw new Error(error?.response?.data?.message);
        }
        throw new Error("Server: Bad Request");
      default:
        break;
    }
  }
  redux.dispatch(setSubmitIsLoading(false));
  redux.dispatch(setServerValidation({}))
  return response;
};
export let ActivityLogs = async (url, formData) => {
  let ActivityLogs = {
    status: 204, //No Content
    data: null,
  };
  let logs_title = ""
  let logs_description = ""
  let api_link = ""

  if (url == `${NEXTAUTH_URL}api/users/login`) { logs_title = "Login"; logs_description = "User Logged in!"; api_link = `${NEXTAUTH_URL}api/activity_logs` }
  else if (url == `/api/membership`) {
    api_link = `/api/activity_logs`;
    if (formData.payment_status == "Verified") {
      logs_title = "Memberhsip Verified";
      if (formData.first_time_applicant == 0) {
        logs_description = "Renewal" + " of " + formData.membership_type + " - " + formData.first_name + " " + formData.last_name;
      } else {
        logs_description = "New Member" + " of " + formData.membership_type + " - " + formData.first_name + " " + formData.last_name;
      }
    } else {
      logs_title = "Membership";
      if (formData.first_time_applicant == 0) {
        logs_description = "Renewal" + " of " + formData.membership_type + " - " + formData.first_name + " " + formData.last_name;
      } else {
        logs_description = "New Member" + " of " + formData.membership_type + " - " + formData.first_name + " " + formData.last_name;
      }
    }
  } else if (url == `/api/inquiries`) {
    api_link = `/api/activity_logs`
    logs_title = "Inquiries"
    logs_description = formData.is_replied != true ? formData.category : "Update Status of " + formData.first_name + " " + formData.last_name

  } else if (url == `/api/donations` && formData.amount_donate != undefined) {
    api_link = `/api/activity_logs`;
    logs_title = "Donation";
    if (formData.payment_method == "Bank_Transfer") {
      logs_description = "Donated a Php" + formData.amount_donate + " Thru " + "Bank Transfer"
    } else {
      formData.payment_method + " To " + formData.all + " " + formData.ear + " " + formData.cis + " " + formData.others + " " + formData.othersInput
    }
  }
  else if (url == `/api/jobs`) {
    api_link = `/api/activity_logs`
    logs_title = "Created a Job"
    logs_description = formData.title + " - " + formData.job_type
  }
  // else if (url == `/api/jobs?id=${formData.id}`) {
  //   api_link = `/api/activity_logs`
  //   logs_title = "Update a Job"
  //   logs_description = formData.title + " - " + formData.job_type
  // }
  else if (url == `/api/events`) {
    api_link = `/api/activity_logs`
    logs_title = "Created an Event"
    logs_description = formData.title
  }
  else if (url == `/api/maintenance/user_roles`) {
    api_link = `/api/activity_logs`
    if (formData.id != null) {
      logs_title = "Update"
      logs_description = " User role access of " + formData.name
    } else {
      logs_title = "Created new user role"
      logs_description = "Role name - " + formData.name
    }
  } else if (url == `/api/maintenance/branch`) {
    api_link = `/api/activity_logs`
    if (formData.id != null) {
      logs_title = "Update"
      logs_description = "Branch of " + formData.branch.name
    } else {
      logs_title = "Created branch"
      logs_description = "Branch name - " + formData.branch.name
    }
  }

  ActivityLogs = await axios({
    method: "post",
    url: api_link,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": PUBLIC_API_KEY,
    },
    data: {
      logs: {
        title: logs_title,
        description: logs_description,
        user_id: UserSession?.result?.id,
        email: UserSession?.result?.id ? UserSession?.result?.username : formData?.email ? formData?.email : formData?.username
      }
    },
  });
  return ActivityLogs;
};