import { useSession, getSession } from 'next-auth/react'
import store from 'store2'
import { decrypt, encrypt } from '@/kyna/lib/crypto'
import { isEmpty } from 'lodash'

export const PageAuth = async context => {
  const session = await getSession({ req: context.req })

  if (!session) {
    return {
      redirect: {
        destination: '/account/login',
        permanent: false
      }
    }
  }

  return false
}

const CheckPermissionItem = (decryptedData, moduleToCheck, action) => {
  const currentModule = decryptedData.find(
    UserSystemModule => UserSystemModule.name == moduleToCheck
  )
  // console.log("currentModule", currentModule)

  if (
    currentModule &&
    currentModule.settings.find(
      setting => setting.action == action && setting.permission
    )
  ) {
    return true
  }
}

export const CheckPermission = (module, action) => {
  const UserData = store('UserSession')
  // console.log("User SESSION Data", UserData)
  // console.log("User SESSION module", module)
  // console.log("User SESSION action", action)

  // const decryptedData = decrypt(UserData?.user_role.permissions)
  const decryptedData = decrypt(UserData?.result?.user_role.permissions)
  // console.log("decryptedData", decryptedData)

  // console.log("module is array", Array.isArray(module) );
  // console.log("module is array", module );

  if (decryptedData) {
    if (Array.isArray(module)) {
      let hasView = 0

      const subModulesAccess = module.map(moduleItem => {
        if (
          CheckPermissionItem(decryptedData, moduleItem?.moduleName, action)
        ) {
          hasView = 1
        }
      })

      // console.log("subModulesAccess", subModulesAccess)
      // if( subModulesAccess.includes(true) ){
      //     return true;
      // }

      return hasView
    } else {
      return CheckPermissionItem(decryptedData, module, action)
    }
  }

  return false
}
