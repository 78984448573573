import cryptoJs from 'crypto-js';
import { DATA_SECRET_KEY } from './global';

export const decrypt = (data) => {
    // console.log("decrypt data", data)
    
    try {
        const bytes = cryptoJs.AES.decrypt(data, DATA_SECRET_KEY);
        const decryptedBytes = bytes.toString(cryptoJs.enc.Utf8);
        // console.log("decryptedBytes", decryptedBytes)

        if( decryptedBytes ){
            return JSON.parse(decryptedBytes);
        }    

    } catch (error) {

        console.log(error)
    }
}

export const encrypt = (data) => {
    return cryptoJs.AES.encrypt(data, DATA_SECRET_KEY).toString();
}