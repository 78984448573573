import * as t from "../types";

export const updateAppSetup = (KynaAppData) => ({
    type: t.UPDATE_APP_SETUP,
    payload: KynaAppData,
});

export const updateSystemPermissions = (SystemPermissions) => ({
    type: t.UPDATE_SYSTEM_MODULES_PERMISSION,
    payload: SystemPermissions,
});

export const updateFormData = (formData) => ({
    type: t.UPDATE_FORM_DATA,
    payload: formData,
});

export const setUser = (userData) => ({
    type: t.SET_USER,
    payload: userData,
});

export const setInfo = (name) => ({
    type: t.SET_NAME,
    payload: name,
});

export const setServerValidation = (serverValidation) => ({
    type: t.SERVER_VALIDATION_ERROR,
    payload: serverValidation,
})

export const setSubmitIsLoading = (isLoading) => ({
    type: t.SUBMIT_IS_LOADING,
    payload: isLoading,
})